.analyzeHeader {
  color: #555A6E;
  font-size: 32px;
  font-weight: 700;
}

.analyzeOptionSpacer {
  width: 50px;
}

.analyzeOption {
  align-items: center;
  background-color: #DBDFF4;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 160px;
  justify-content: center;
  width: 160px;
}

.analyzeOption > span {
  color: #555A6E;
  font-size: 24px;
  font-weight: 700;
  margin-top: 4px;
}

.analyzeOption > svg {
  height: 60px;
  width: 60px;
}

.analyzeOptionSelected {
  background-color: #E0C7FF;
}

.optionContainer {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}
